<template>
  <div class="my-bg">
    <b-container>
      <b-row class="d-flex align-items-center justify-content-center">
        <b-col md="6" sm="6" class="py-3 py-xs-0">
          <b-container class="h-100">
            <div class="d-flex align-items-center h-100 pt-4">
              <div class="d-flex flex-column">
                <div class="pt-2 text-left">
                  <h1 class="font-weight-bolder text-colorBlue">
                    Pakistan Where World-Class Medical Tourism
                  </h1>
                  <h1 class="font-weight-bolder text-colorBlue">
                    Meets Unmatched Value
                  </h1>
                </div>
                <div class="pt-1 text-left">
                  <h4 class="font-weight-normal text-colorGreen">
                    Experience affordable excellence with our extensive <br />
                    range of procedures
                  </h4>
                </div>
                <div class="pt-2 d-flex justify-content-start">
                  <b-button
                    pill
                    variant="colorGreen"
                    size="md"
                    class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
                    @click="contactPage"
                  >
                    <img
                      src="@/assets/images/icons/button2.svg"
                      class="logo mr-50"
                    />
                    Contact Us
                  </b-button>
                  <b-button
                    pill
                    variant="colorGreen"
                    size="md"
                    class="first-button text-light font-weight-bolder mr-2 mb-1 mb-xs-0"
                    @click="getQuote"
                  >
                    <img
                      src="@/assets/images/icons/button1.svg"
                      class="mr-50"
                    />Get a Quote
                  </b-button>
                </div>
              </div>
            </div>
          </b-container>
        </b-col>
        <b-col>
          <img
            src="@/assets/images/logo/medask.png"
            width="80%"
            class="ml-md-4"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  methods: {
    contactPage() {
      this.$router.push({ name: "ContactUs" });
    },
    getQuote() {
      this.$router.push({ path: "/", hash: "#getQuote" });
    },
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/aboutUs/about1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.button-container .first-button:hover .logo {
  content: url("~@/assets/images/icons/medical-cross-stroke.svg");
}
</style>
